* {
    margin: 0;
    padding: 0;
}

body {
    background: #39465c;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

img {
    max-width: 90%;
    width: 800px;
}
